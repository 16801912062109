export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [2,3];

export const dictionary = {
		"/(app)": [~5,[2]],
		"/(app)/dashboard": [6,[2,3]],
		"/(app)/dashboard/branches": [7,[2,3]],
		"/(app)/dashboard/branches/create": [8,[2,3]],
		"/(app)/dashboard/branches/edit/[branch_slug]": [9,[2,3]],
		"/(app)/dashboard/contacts": [10,[2,3]],
		"/(app)/dashboard/contacts/edit": [~11,[2,3]],
		"/(app)/dashboard/feedbacks": [12,[2,3]],
		"/(app)/dashboard/franchise": [13,[2,3]],
		"/(app)/dashboard/franchise/editform": [14,[2,3]],
		"/(app)/dashboard/franchise/submits/[submit_id]": [~15,[2,3]],
		"/(app)/dashboard/jobs": [16,[2,3]],
		"/(app)/dashboard/jobs/[submission_id]": [~17,[2,3]],
		"/(app)/dashboard/menus": [~18,[2,3]],
		"/(app)/dashboard/menus/campaigns/new": [19,[2,3]],
		"/(app)/dashboard/menus/categories/new": [21,[2,3]],
		"/(app)/dashboard/menus/categories/[category_id]/edit": [~20,[2,3]],
		"/(app)/dashboard/menus/menu/new": [23,[2,3]],
		"/(app)/dashboard/menus/menu/[menu_slug]/edit": [~22,[2,3]],
		"/(app)/dashboard/menus/products/new": [~25,[2,3]],
		"/(app)/dashboard/menus/products/[product_id]/edit": [~24,[2,3]],
		"/(app)/dashboard/parts": [26,[2,3]],
		"/(app)/dashboard/parts/[part_slug]": [~27,[2,3]],
		"/(app)/dashboard/settings": [~28,[2,3]],
		"/(app)/dashboard/tv": [29,[2,3]],
		"/(app)/dashboard/users": [30,[2,3]],
		"/(foodmenu)/foodmenu/[menu_slug]": [~39,[4]],
		"/(app)/franchise": [~31,[2]],
		"/(app)/hakkimizda": [32,[2]],
		"/(app)/iletisim": [~33,[2]],
		"/(app)/is-basvurusu": [~34,[2]],
		"/(app)/login": [~35,[2]],
		"/(app)/subelerimiz": [~36,[2]],
		"/(app)/subelerimiz/[branch_slug]": [~37,[2]],
		"/(tv)/tv": [~40],
		"/(app)/urunlerimiz": [~38,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';